<script lang="ts">
import { useQuery } from '@tanstack/vue-query'
import { useRouteQuery } from '@vueuse/router'
import Crumbs from './components/Crumbs.vue'
</script>

<script lang="ts" setup>
const id = useRouteQuery('id')
const visible = ref(false)
const currentImg = ref('')

const { data, isLoading } = useQuery({
  queryKey: [apis.articleDetail.id, id],
  enabled: toRef(() => !!id.value),
  refetchOnWindowFocus: false,
  queryFn: () => apis.articleDetail({ id: Number(id.value) }),
})
const detail = computed(() => data.value)

function showImage(ev: any) {
  if (ev.target?.tagName === 'IMG') {
    currentImg.value = ev.target?.src
    visible.value = true
  }
}
</script>

<template>
  <div class="page" @click="showImage" data-test-id="64q1">
    <div class="page-content page-content-width">
      <Crumbs />
      <article class="article">
        <ASpin :spinning="isLoading">
          <h1>{{ currentLocale === "zh" ? detail?.title : detail?.title_en }}</h1>
          <div class="main" v-html="currentLocale === 'zh' ? detail?.content : detail?.content_en" />
        </ASpin>
      </article>
    </div>

    <AImage
      :src="currentImg"
      :style="{ display: 'none' }"
      :preview="{
        visible,
        onVisibleChange: (e) => {
          visible = e;
        },
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
.page {
  overflow: hidden;
}

.page-content {
  overflow: hidden;
  border-radius: 30px;
  margin: 40px auto 0;

  .crumbs-wrapper {
    padding: 40px 0 20px 40px;
    background: linear-gradient(180deg, #2c3967, #202c52);
    color: var(--secondary-text);
    font-size: 14px;
  }

  .article {
    min-height: 800px;
    padding-top: 30px;
    background: #202c52;
    line-height: 1.6em;
    word-wrap: break-word;

    h1 {
      color: var(--first-text);
      font-size: 26px;
      font-weight: 700;
      line-height: 1.6em;
      text-align: center;
    }

    .main {
      padding: 20px 40px;
      color: var(--secondary-text);
      font-size: 18px;
      line-height: 30px;
    }

    :deep(img) {
      width: auto !important;
      max-width: 100%;
      height: auto !important;
      max-height: 100%;
    }
  }
}

@media bp-lt-tabletl {
  .page-content {
    padding: 0;
    border-radius: 16px;
    margin: 16px auto 0;

    .crumbs-wrapper {
      padding: 14px 16px;
    }

    .article {
      min-height: 300px;
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;

      .main {
        padding: 20px 16px;
        color: var(--secondary-text);
      }
    }
  }
}
</style>
